import Layout from "./src/components/layout"
import * as Sentry from "@sentry/react";
// normalize CSS across browsers
import "./src/css/normalize.css"

// custom CSS styles
import "./src/css/style.css"
import React from "react"
import {AnimatePresence} from "framer-motion";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

Sentry.init({
    dsn: "https://002879032b6db3e393e7f6d50de622bf@o4507259146862592.ingest.us.sentry.io/4507259149418496",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    ignoreErrors: [
      'fbq is not defined'
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost"],
    // tracePropagationTargets: ["localhost", /^https:\/\/cooperationcoffee.myshopify.com\/api/, /^https:\/\/workers.cooperation.coffee/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

export const wrapPageElement = ({ element, props }) => {
    // console.log("wrapPageElement", props);
    return (
        <>
            <AnimatePresence>
                <Layout>
                  {element}
                </Layout>
            </AnimatePresence>
            <ToastContainer
                position="bottom-right"
                autoClose={2000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </>
    )
}

export const shouldUpdateScroll = ({
}) => {
  document.querySelector('main').scrollTo(0,0);
  return true;
}
