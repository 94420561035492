import React from "react"
import {Link, navigate} from "gatsby";
import {StyledLink} from "./layout";
import {FiFacebook, FiInstagram, FiMail} from 'react-icons/fi';
import {useIsMobile} from "../utils";


const Footer = () => {
    const {isMobile} = useIsMobile();
    return (
        <div
            style={{
                background: '#000',
                display: "flex",
                flexDirection: isMobile ? 'column' : 'row',
                alignItems: "center",
                justifyContent: "space-between",
                padding:
                    "28px 5rem",
                color: '#ece4d9'
            }}
        >
            <div style={{display: 'flex',
        }}>
                <div>
                    <div style={{
                        fontWeight: 'bold',
                      textAlign: isMobile ? 'center' : 'start',
                    }}>
                        Cooperation 合作咖啡
                    </div>
                    <div
            style={{ fontSize: 14,
                      textAlign: isMobile ? 'center' : 'start',
          }}>
                        台中市福田三街 165 號<br/>
                        04-2263-3221<br/>
                        營業時間<br/>
                        平日 10:00 - 18:00 <br/>
                        假日 09:00 - 17:00
                    </div>
                </div>
            </div>
            <div style={{
                display: 'flex',
                flexDirection: isMobile ? 'column' : 'row',
                margin: isMobile ? 24 : 0,
                alignItems: 'center',
            }}>
                {
                    isMobile ? [
                            <StyledLink title=" ABOUT " link="/about"/>,
                            <div style={{height: 8}}/>,
                            <StyledLink title="GUIDES" link="/guides"/>,
                            <div style={{height: 8}}/>,
                            <StyledLink title=" SHOP " link="/shop"/>,
                            <div style={{height: 8}}/>,
                            <StyledLink title=" SUBSCRIBE " link="/subscribe"/>,
                            <div style={{height: 8}}/>,
                            <StyledLink title=" WHOLESALE " link="/wholesale"/>,
                            <div style={{height: 8}}/>,
                            <StyledLink title=" FAQ " link="/faq"/>
                        ] :
                        <div style={{display: 'flex'}}>
                            <StyledLink title=" ABOUT " link="/about"/>
                            <div style={{width: 24}}/>
                            <StyledLink title=" SHOP " link="/shop"/>
                            <div style={{width: 24}}/>
                            <StyledLink title=" SUBSCRIBE " link="/subscribe"/>
                            <div style={{width: 24}}/>
                            <StyledLink title=" WHOLESALE " link="/wholesale"/>
                            <div style={{width: 24}}/>
                            <StyledLink title=" FAQ " link="/faq"/>
                        </div>
                }
                <div style={{width: 32, height: 24}}></div>
                <div
                    style={{display: 'flex', alignItems: 'center'}}
                >
                    <img 
                        src="/facebook.svg"
                        style={{ cursor: 'pointer'}}
                        onClick={() => {
                            window.open('https://www.facebook.com/cooperationcoffee/', '_blank').focus();
                        }}
                     />
                    <div style={{width: 10}}></div>
                    <img 
                        src="/instagram.svg"
                        style={{ cursor: 'pointer'}}
                        onClick={() => {
                            window.open('https://www.instagram.com/cooperation.coffee/', '_blank').focus();
                        }}
                     />
                    <div style={{width: 10}}></div>
                    <img 
                        src="/email.svg"
                        style={{ cursor: 'pointer'}}
                        onClick={() => {
                            window.open('mailto:cooperation.coffee@gmail.com', '_self' );
                        }}
                     />
                </div>
            </div>
        </div>
    )
}

export default Footer;
