import React, {useEffect, useState } from "react"
import { createGlobalState } from 'react-hooks-global-state';


import { client, shopify } from "./client";
import { Checkout } from "./models/checkout";
const { useGlobalState } = createGlobalState({
  checkoutId: '',
  itemCount: 0,
  checkout: null,
  initialized: false,
  vacation: null,
});


export const useCart = () => {
    const [checkoutId, setCheckoutId] = useGlobalState('checkoutId');
    const [checkout, setCheckout] = useGlobalState('checkout');
    const [itemCount, setItemCount] = useGlobalState('itemCount');
    const [initialized, setInitialized] = useGlobalState('initialized');
    const [vacation, setVacation] = useState(null);

    const fetchCheckout = async (id = '') => {
        if (!id && !checkoutId) return null;
        const data = await client.request(`
          query {
            vacation: products(first: 1, query: "tag:休假模式") { 
              edges { node { 
                handle title descriptionHtml
              }} 
            }
            node(id: "${id || checkoutId}") {
              ...on Checkout {
                subtotalPrice {
                    amount
                }
                totalPriceV2 {
                    amount
                }
                note
                webUrl
                order {
                    id
                }
                paymentDue {
                  amount
                }
                email totalPrice { amount }
                lineItems(first: 100) {
                    edges {
                        node {
                            id
                            title
                            quantity
                            customAttributes {
                              key
                              value
                            }
                            variant {
                                id
                                title
                                image {
                                    url
                                }
                                priceV2 {
                                    amount
                                }
                                product {
                                    handle
                                    productType
																		metafields(identifiers: [{ key: "eng_name", namespace: "custom"}]) {
																			id
																			value
																			key
                                    }
                                }
                            }
                            unitPrice {
                                amount
                            }
                        }
                    }
                }
              }
            }
          }
        `);
        if (!data?.node || data?.node?.order) {
            return createCheckout();
        }
        const disabledItems = data?.node?.lineItems?.edges?.filter(e => !e?.node?.variant);
        if (disabledItems.length > 0) {
          return createCheckout();
        }
        const checkout = Checkout.fromShopify(data);
        const vacation = data?.vacation?.edges?.[0]?.node?.descriptionHtml;
        if (vacation) {
          setVacation(vacation.replace(/<br>/g, '\n').replace(/<[^>]*>?/gm, ''));
        }

        if (checkout?.items?.length) {
            setItemCount(checkout?.items?.length);
        }
        setCheckout(checkout);
        return checkout;
    }

    const createCheckout = async () => {
        const checkout = await shopify.checkout.create();
        localStorage.setItem('checkout', checkout.id);
        setCheckoutId(checkout.id);
        setCheckout(checkout);
        setInitialized(true);
        return checkout;
    }

    useEffect(() => {
        let localCheckoutId = localStorage.getItem('checkout');
        if (localCheckoutId) {
          setCheckoutId(localCheckoutId);
          fetchCheckout(localCheckoutId).then(() => {
            setInitialized(true);
          });
        } else {
          createCheckout().then(() => {
            setInitialized(true);
          });
        }
    }, [])



    const addItem = async (items) => {
        setItemCount(itemCount + 1)
        if (!checkoutId) {
            const checkout = await createCheckout();
            await shopify.checkout.addLineItems(checkout.id, items);
        } else {
            await shopify.checkout.addLineItems(checkoutId, items);
        }
        fetchCheckout();
    };

    const removeItem = async  (id) => {
        await shopify.checkout.removeLineItems(checkoutId, [id]);
        fetchCheckout();
    }

    const updateItems = async (items) => {
      await shopify.checkout.updateLineItems(checkoutId, items);
      fetchCheckout();
    }

    const clearCart = async () => {
      if (!checkout?.items) { return }
      await shopify.checkout.removeLineItems(checkoutId, checkout?.items?.map(i => i.id));
      fetchCheckout();
    }

    const updateCheckoutNote = async (note) => {
      await client.request(`
						mutation checkoutDiscountCodeApplyV2($checkoutId: ID!) {
							checkoutAttributesUpdateV2(
								checkoutId: $checkoutId, 
								input: { 
									note: "${note}"
								}
							) {
								checkout {
									id
								}
							}
						}
        `, {
          checkoutId,
        });
      fetchCheckout();
    }

    return {
      addItem,
      checkoutId,
      itemCount,
      fetchCheckout,
      createCheckout,
      removeItem,
      checkout,
      initialized,
      clearCart,
      updateItems,
      updateCheckoutNote,
      vacation,
    }
}

export const CartContext = React.createContext({
  addItem: () => {},
  checkoutId: '',
  itemCount: 0,
  fetchCheckout: () => {},
  createCheckout: () => {},
  removeItem: () => {},
  checkout: null,
  initialized: false,
  clearCart: () => {},
  vacation: null,
})

