export class ProductOption {
    public  name: string;
    public  id: string;
    public  values: string[];
    constructor(
       data: Partial<ProductOption>
    ) {
        Object.assign(this, data);
    }
}

export class ProductVariant {
    public  name: string;
    public  id: string;
    public  price: number;
    public compareAtPrice: number | null
    constructor(
       data: Partial<ProductVariant>
    ) {
        Object.assign(this, data);
    }
}

export class Product {
    public id: string;
    public name: string;
    public productType: string;
    public tags: string[];
    public available: boolean;

    public specName: string;
    public count: number;
    public price: number;
    public childProducts?: Product[];
    public shortDescription?: string;

    public coverUrl: string;
    public imageUrls: string[];
    public thumbnailUrl: string;
    public description: string;

    public variants?: ProductVariant[];
    public options: ProductOption[];

    public minPrice: number;
    public maxPrice: number;

    public detailText?: string;
    public engName?: string;
    public link?: string;
    public detailImageUrl?: string;


    constructor(data : any = {}) {
        Object.assign(this, data);
    }

    static fromShopify({ node }): Product {
        let maxPrice,minPrice;

        const variants = (node?.variants?.edges ?? []).map(({ node}) => {
            const price =  parseFloat(node.priceV2.amount);
            const compareAtPrice =  parseFloat(node.compareAtPriceV2?.amount) || null;
            if (price < minPrice || !minPrice) {
                minPrice = price;
            }
            if (price > maxPrice || !maxPrice) {
                maxPrice = price;
            }
            return new ProductVariant({
                id: node.id,
                name: node.title,
                price,
                compareAtPrice,
            });
        })
        const metafields = (node?.metafields ??  []).reduce((acc, node) => {
          if (!node) return acc;
          if (node.key === '_long_description') {
            acc.detailText = node.value;
          } else if (node.key === 'product_detail_image') {
            acc.detailImageUrl = node.reference.image.url;
          } else if (node.key === 'eng_name') {
            acc.engName = node.value;
          } else if (node.key === '_link') {
            acc.link = node.value;
          } else if (node.key === 'short_description') {
            acc.shortDescription = node.value;
          }
          return acc;
        }, {});

        const [coverUrl, ...imageUrls] = (node?.images?.edges ?? []).map((v) => v.node.url);


        return new Product({
            id: node.handle,
            name: node.title,
            productType: node.productType,
            coverUrl,
            imageUrls,
            thumbnailUrl: (node?.images?.edges[0]?.node.thumbnailUrl),
            options: (node?.options ?? []).map((o) => new ProductOption(o)),
            variants,
            maxPrice,
            minPrice,
            available: node.availableForSale,
            description: node.descriptionHtml || node.description,
            tags: node.tags,
            ...metafields,
        });
    }
}
