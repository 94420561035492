import { useState, useEffect, useRef } from "react";
import { graphql, useStaticQuery } from "gatsby";

export class Action {
}

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    },[value]);
    return ref.current;
}



export const useIsMobile = () => {
    const [height, setHeight] = useState<number>(
        typeof  window !== "undefined" ? window.innerHeight: 1080
    );
    const [width, setWidth] = useState<number>(
        typeof  window !== "undefined" ? window.innerWidth : 1920
);

    function handleWindowSizeChange() {
        setWidth(
        typeof  window !== "undefined" ? window.innerWidth : 1920
        );
        setHeight(
            typeof  window !== "undefined" ? window.innerHeight : 1080
        );
    }
    useEffect(() => {
        if (typeof  window == "undefined") {
            return;
        }
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    return  { isMobile : width <= 768, screenWidth: width, screenHeight: height };
}

export const useSiteMetadata = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          image
          siteUrl
        }
      }
    }
  `)

  return data.site.siteMetadata
}
