import React, {useEffect, useState, useContext, useRef} from "react"
import {Link, navigate} from "gatsby";
import {FiShoppingCart, FiSearch} from 'react-icons/fi';
import {useIsMobile} from "../utils";
import { CartContext } from '../cart';
import { HamburgerButton } from "./sidebar";

const Item = ({title, link, active}) => {
    const isActive = window.location.pathname.includes(link);
    return (
        <div
        >
            <Link
              className="head-link"
        to={link} style={{
              ...(isActive && {color: '#373330', textDecoration: 'underline'})
            }}>{title}</Link>
        </div>
    )
}

const Navigation = () => {
    const { screenWidth } = useIsMobile();
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "space-between",
                flexGrow: 1,
                flexBasis: 0,
            }}
        >
            {
                screenWidth < 1160? [
                  <HamburgerButton 
                  />
                ] : [
                    <Item title="ABOUT" link="/about" active={true} key="navi-about"/>,
                    <Item title="GUIDES" link="/guides" active={true} key="navi-guides"/>,
                    <Item title="SHOP" link="/shop" active={true} key="navi-shop"/>,
                    <Item title="SUBSCRIBE" link="/subscribe" active={true} key="navi-subscribe"/>,
                    <Item title="WHOLESALE" link="/wholesale" active={true} key="navi-wholesale"/>,
                    <Item title="FAQ" link="/faq" active={true} key="navi-faq"/>
                ]
            }
        </div>
    );
}

const HeadMenu = () => {
    const { itemCount } = useContext(CartContext);
    const [isSearching, setIsSearching] = useState(false);
    const [search, setSearch] = useState('');
    const inputRef = useRef(null);

    const goSearch = () => {
      setIsSearching(false);
      navigate(`/shop?search=${search}`);
    }

    useEffect(() => {
      if (isSearching && inputRef.current) {
        inputRef.current.focus();
      }
    }, [isSearching]);

    return (
        <div
            style={{
                position: 'sticky',
                top: 0,
                right: 0,
                left: 0,
                zIndex: 100,
            }}
        >
          <div
            style={{
                color: 'black',
                background: 'white',
                display: "flex",
                alignItems: "center",
                padding: "16px 48px",
            }}
          >
            <Navigation/>
            <div style={{
                flexBasis: 0,
                cursor: 'pointer',
                flexGrow: 0.5, alignItems: 'center', justifyContent:　'center', display: 'flex'}}>
                <img src={"/logo.png"} style={{width: 80}} onClick={() => {
                    navigate('/');
                }}/>
            </div>
            <div style={{
                flexBasis: 0,
                position: 'relative',
                display: 'flex', flexGrow: 1, height: 20, justifyContent: 'end'}}>
                <div 
                  onClick={() => {
                    setIsSearching(!isSearching);
                }}
                      style={{ color: 'black', textDecoration: 'none', cursor: 'pointer',

                        marginRight: '16px'
                       }}>
                    <FiSearch size={24}/>
                </div>
                <div onClick={() => {
                    navigate('/cart');
                }}
                      style={{ color: 'black', textDecoration: 'none', cursor: 'pointer' }}>
                    <FiShoppingCart size={24}/>
                <div style={{
            position: 'absolute',
            background: 'black',
            textAlign: 'center',
            color: 'white',
            borderRadius: '50%',
            width: 18,
            height: 18,
            top: -12,
            right: -20,
          }}>
            <div
              style={{
                fontSize: 12,
                position: 'absolute',
                top: -1,
                right: 5.5,
              }}
            >{itemCount}</div></div>
                </div>
            </div>
            </div>
            {
                isSearching && (
                  <>
                    <div
                      style={{
                        textAlign: 'center',
                        padding: '16px',
                        background: 'white'
                      }}
                    >
                      <div
                        style={{
                          maxWidth: '500px',
                          position: 'relative',
                          background: '#efefef',
                          borderRadius: '8px',
                          margin: '0px auto',
                          padding: '4px 8px'
                        }}
                      >
                        <input
                          style={{
                            border: 'none',
                            width: '100%',
                            color: 'black',
                            outline: 'none',
                            background: 'transparent',
                          }}
                          placeholder="請輸入關鍵字"
                          autofocus
                          onChange={(e) => {
                            setSearch(e.target.value);
                          }}
                          value={search}
                          onKeyDown={({ key }) => {
                            if (key === 'Enter') {
                              goSearch();
                            }
                          }}
                          onBlur={() => {
                            setIsSearching(false);
                          }}
                          ref={inputRef}
                        />
                        <div
                          style={{
                            position: 'absolute',
                            right: '12px',
                            top: '2px',
                            cursor: 'pointer',
                          }}
                          onMouseDown={() => goSearch()}
                        >
                          <FiSearch size={16}/>
                        </div>
                      </div>
                    </div>
                    <div
                      style={{
                        position: 'fixed',
                        top: 0,
                        bottom: 0,
                        right: 0,
                        left: 0,
                        background: '#00000060',
                        zIndex: -1
                      }}
                    />
                  </>
                )
            }
        </div>
    )
}

export default HeadMenu;
