import React, {useEffect, useState, useLayoutEffect} from "react"
import {Link, useStaticQuery, graphql, navigate} from "gatsby"
import HeadMenu from "./head-menu";
import Footer from "./footer";
import { Sidebar } from "./sidebar";
import {useIsMobile} from "../utils";
import {useSpring} from "framer-motion";
import { CartContext, useCart } from "../cart";
import Promoter from "./promoter";
import { client } from "../client";
import {Product} from "../models/product";


export const StyledLink = ({link, title}) => {

    const spring = useSpring(0, {damping: 300, stiffness: 1000});

    useLayoutEffect(() => {
        spring.onChange(latest => {
            window.scrollTo(0, latest);
        });
    }, [spring]);

    function moveTo(to) {
        spring.set(window.pageYOffset, false);
        return new Promise((resolve) => {
            setTimeout(() => {
                spring.set(to);
                resolve(null);
            }, 50);
        });
    }
    return <span 
      className="head-link"
    onClick={async () => {
        await moveTo(0);
        navigate(link);
    }} 
    >{title}</span>;
}

export const BlackLink = ({link, title}) =>
    <Link to={link} style={{
        color: '#000',
        textDecoration: "none",

    }}>{title}</Link>

export const SegmentLink = ({link, children}) =>
    <Link to={link} style={{
        color: '#000',
        textDecoration: "none",
    }}>{children}</Link>

export const LayoutContext = React.createContext({
  isSidebarOpen: false,
  setIsSidebarOpen: (value) => {},
  countries: [],
});

const Layout = ({isHomePage, children, backgroundColor = 'white'}) => {
    const {screenWidth, isMobile, screenHeight} = useIsMobile();
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);
    const [promotions, setPromotions] = useState([]);
    const [countries, setCountries] = useState([]);
    const [tags, setTags] = useState([]);
    const cart = useCart();

    if (typeof  window === 'undefined') {
        return <></>;
    }

    useEffect(() => {
      client.request(`
        query @inContext(country: US, language: EN) {
          localization {
            availableCountries {
              isoCode
              name
              availableLanguages {
                isoCode
                endonymName
              }
            }
          }
          promotions: products(first: 30, query: "product_type:置頂消息") {
            edges {
              node {
                description
                metafields(identifiers:[{ key: "_link", namespace: "custom"}]) {
                  value
                  key
                }
              }
            }
          }
          products(first: 100) {
            edges {
              node {
                tags
              }
            }
          }
        }
      `).then((data) => {
        const promotions = data.promotions.edges.map(Product.fromShopify);
        setPromotions(promotions);
        setCountries(data.localization.availableCountries);
        const tags = [...new Set(data.products.edges.reduce((acc, product) => [...product.node.tags, ...acc], []).filter((tag) => ['產地-', '焙度-', '風味-', '工具-'].some((t) => tag.includes(t))))];
        setTags(tags);
      })
    }, []);

    return (
    <CartContext.Provider value={cart}>
      <LayoutContext.Provider value={{isSidebarOpen, setIsSidebarOpen, countries }}>
        <div>
            {
                screenWidth < 1160 && <Sidebar 
                  isOpen={isSidebarOpen} setIsOpen={setIsSidebarOpen} 
                  tags={tags}
                />
            }
            <div 
              className="global-wrapper" 
              data-is-root-path={isHomePage} 
              style={{
                padding: 0, 
                minHeight: screenHeight -346,
                position: 'relative'
              }}
            >
              {
                promotions.length > 0 && <Promoter promotions={promotions} />
              }
              <HeadMenu />
              <main
                style={{
                  width: '100%',
          overflow: 'auto',
          backgroundColor,
                  overflowX: 'hidden',
                  
                }}
              >
                <div
                  style={{ minHeight: 'calc(100vh - 247px)', position: 'relative' }}
                >
                  {children}
                </div>
            <Footer/>
            </main>

                {/*<footer>*/}
                {/*  © {new Date().getFullYear()}, Built with*/}
                {/*  {` `}*/}
                {/*  <a href="https://www.gatsbyjs.com">Gatsby</a>*/}
                {/*  {` `}*/}
                {/*  And <a href="https://wordpress.org/">WordPress</a>*/}
                {/*</footer>*/}
            </div>
        </div>
        </LayoutContext.Provider>
        </CartContext.Provider>
    )
}

export default Layout
