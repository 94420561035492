import React, {useState, useContext, useEffect} from "react";
import {motion, useSpring} from "framer-motion";
import {Link, navigate} from "gatsby"
import beanFilterMap from "../beanFilterMap.js";
import { LayoutContext } from './layout';

const Path = (props: any) => {
    return (
        <motion.path
            fill="transparent"
            stroke="inherit"
            strokeWidth="3"
            strokeLinecap="round"
            {...props}
        />
    );
};

export const SideBarIcon = (props: any) => {
    return (
        <motion.div
            initial={false}
            style={{stroke: '#fff'}}
            animate={props.visible ? "open" : "closed"}
        >
            <svg width="23" height="23" viewBox="0 0 23 23">
                <Path
                    variants={{
                        closed: {d: "M 2 2.5 L 20 2.5"},
                        open: {d: "M 3 16.5 L 17 2.5"}
                    }}
                />
                <Path
                    d="M 2 9.423 L 20 9.423"
                    variants={{
                        closed: {opacity: 1},
                        open: {opacity: 0}
                    }}
                    transition={{duration: 0.1}}
                />
                <Path
                    variants={{
                        closed: {d: "M 2 16.346 L 20 16.346"},
                        open: {d: "M 3 2.5 L 17 16.346"}
                    }}
                />
            </svg>
        </motion.div>
    );
};
const Line = ({ width }) => (
    <div style={{
        backgroundColor: 'black',
        width,
        height: 2,
        margin: '2px 14px',
        borderRadius: 8,
    }}></div>
)

export const HamburgerButton = ({x, width}) => {
    const { isSidebarOpen, setIsSidebarOpen } = useContext(LayoutContext);
    return (
        <div
            style={{
                borderRadius: 32,
                width: 48,
                height: 48,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                cursor: 'pointer',
                zIndex: 101,
            }}
            onClick={() => {
                setIsSidebarOpen(!isSidebarOpen);
            }}
        >
            <Line width={16}/>
            <Line width={12}/>
        </div>
    )
}

const Item = ({title, onClick, isActive}) => {
    return (
        <div
          style={{ marginBottom: 24 }}
          onClick={() => {
            onClick();
          }}
        >
            <span 
      className="head-link"
      style={{
          ...(isActive && {color: '#373330', textDecoration: 'underline'})
            }}>{title}</span>
        </div>
    )
}


export const Sidebar = ({width = 320, color = "#000", isOpen, setIsOpen, tags}) => {
    const [isShopExpanded, setIsShopExpanded] = useState(false)
    const [isCoffeeExpanded, setIsCoffeeExpanded] = useState(false)
    const [coffeeFilter, setCoffeeFilter] = useState(null);
    const [shopFilter, setShopFilter] = useState(null);
    const x = useSpring(0, {stiffness: 400, damping: 40})
    const goToLink = (link) => {
      setIsOpen(false);
      x.set(-width);
      navigate(link);
    }
    const isActive = (url) => decodeURI(window.location.href).includes(url);

    useEffect(() => {
      !isOpen ? x.set(-width) : x.set(0)
    }, [isOpen]);

    return (
        <div
        >
            <motion.div
                className="sidebarContent"
                style={{
                    position: 'fixed',
                    backgroundColor: 'white',
                    width: width,
                    height: '100%',
                    zIndex: 200,
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    boxSizing: 'border-box',
                    ...(
                        isOpen ? {
                            boxShadow: '16px 0 32px -16px #000',
                        } : {}
                    ),
                    padding: '0px 64px 64px 48px',
                    x,
                }}
                transition={{
                    type: "spring",
                    stiffness: 400,
                    damping: 40
                }}
                initial={{x: -width}}
            >
              <div
                style={{
                  position: 'sticky',
                  top: 0,
                  flexDirection: 'column',
                }}
              >
                <div
                  style={{
                    background: 'white',
                    height: 72,
                    width,
                  }}
                >
                  <div style={{ paddingTop: 36 }} >
                    <HamburgerButton x={x} width={width} />
                  </div>
                </div>
                <div
                  style={{
                    background: 'linear-gradient(to top, transparent, white)',
                    height: 96,
                    width,
                  }}
                >
                </div>
              </div>
              <Item title="ABOUT" 
                onClick={() => goToLink('/about')}
                isActive={isActive('/about')}
              />
              <Item title="GUIDES" 
                onClick={() => goToLink('/guides')}
                isActive={isActive('/guides')}
              />
              <Item title="SHOP" 
                onClick={() => setIsShopExpanded(!isShopExpanded)}
                isActive={isActive('/shop')}
              />
              {
                isShopExpanded && <div
                  style={{ marginLeft: 24, marginTop: -8 }}
                >
                  <Item
                    title="全部商品"
                    onClick={() => { 
                      setIsCoffeeExpanded(false); 
                      goToLink('/shop');
                    }}
                  />
                  <Item
                    title="咖啡豆"
                    onClick={() => { 
                      setIsCoffeeExpanded(!isCoffeeExpanded); }}
                  />
                    {
                      isCoffeeExpanded && <div
                        style={{ marginLeft: 24, marginTop: -8 }}
                      >
                      {
                    ['產區', '工具', '風味', '烘焙'].map((key) =>  (
                          <>
                            <Item title={key}
                              onClick={() => coffeeFilter === key ? setCoffeeFilter(null) : setCoffeeFilter(key)}
                            />
                            {
                              coffeeFilter === key && <div
                                style={{ marginLeft: 24, marginTop: -8 }}
                              >
                                {
                                  tags.filter((tag) => {
                                    if (key === '產區') {
                                        return tag.includes('產地-');
                                    } else if (key === '烘焙') {
                                        return tag.includes('焙度-');
                                    }
                                    return tag.includes(key);
                                  })
                                  .map((item) => item.split('-')[1])
                                  .map((item) => (
                                    <Item title={item}
                                      onClick={() => goToLink(`/shop?search=${item}`)}
                                      isActive={isActive(item)}
                                    />
                                  ))
                                }
                              </div>
                            }
                          </>
                        ))
                    }
                    </div>
                  }
                  <Item
                    title="掛耳咖啡"
                    onClick={() => { 
                      setIsCoffeeExpanded(false); 
                      goToLink('/shop?type=掛耳式咖啡');
                    }}
                    isActive={isActive('掛耳')}
                  />
                  <Item
                    title="咖啡禮盒"
                    onClick={() => { 
                      setIsCoffeeExpanded(false); 
                      goToLink('/shop?type=咖啡禮盒');
                    }}
                    isActive={isActive('禮盒')}
                  />
                  <Item
                    title="週邊商品"
                    onClick={() => { 
                      setIsCoffeeExpanded(false); 
                      goToLink('/shop?type=週邊商品');
                    }}
                    isActive={isActive('週邊')}
                  />
                </div>
              }
              <Item title="SUBSCRIBE" 
                onClick={() => goToLink('/subscribe')}
                isActive={isActive('/subscribe')}
              />
              <Item title="WHOLESALE" 
                onClick={() => goToLink('/wholesale')}
                isActive={isActive('/wholesale')}
              />
              <Item title="FAQ" 
                onClick={() => goToLink('/faq')}
                isActive={isActive('/faq')}
              />
            </motion.div>
        </div>
    )
}
